<template>
  <v-card width="350" height="100vh">
    <v-form
      @submit.prevent="
        setDistrict({ name: districtName, color: districtColor })
      "
    >
      <v-col align="center">
        <h3 class="mt-5 mb-2">Паспорт района</h3>
        <v-row dense>
          <v-card-text class="text-start">Название района</v-card-text>
          <v-text-field
            type="text"
            v-model="districtName"
            outlined
            hide-details
          ></v-text-field>
          <v-card-text class="text-start">Цвет района</v-card-text>
          <v-color-picker
            v-model="districtColor"
            class="ma-2"
            hide-canvas
            hide-mode-switch
          ></v-color-picker>
        </v-row>
        <div class="mb-5">
          <v-btn type="submit" class="mt-5 font-weight-bold">Сохранить</v-btn>
        </div>
      </v-col>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "DistrictsPassport",

  props: { properties: Object, setDistrict: Function },

  data() {
    return {
      districtName: null,
      districtColor: null
    };
  },

  watch: {
    properties: function(newValue) {
      this.districtName = newValue.name;
      this.districtColor = newValue.color;
    }
  }
};
</script>
