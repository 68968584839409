<template>
  <v-container fluid>
    <v-card>
      <v-row dense>
        <v-col align="center" justify="center">
          <vl-map
            data-projection="EPSG:4326"
            style="width: 100%; height: 100vh;"
            :key="mapChange"
            ref="map"
          >
            <vl-view :zoom.sync="zoom" :center.sync="center"></vl-view>

            <vl-layer-tile
              v-for="(item, index) in $store.state.settings.maps"
              :z-index="index"
              :opacity="item.alpha / 100"
              :key="item.id"
            >
              <vl-source-osm
                v-if="(item.id === 'osm') & item.visible"
              ></vl-source-osm>
              <vl-source-xyz
                v-if="(item.id === '2gis') & item.visible"
                url="http://tile0.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1&ts=online_sd"
              ></vl-source-xyz>
              <vl-source-bingmaps
                v-if="(item.id === 'bingMapsAerial') & item.visible"
                :api-key="$store.state.settings.apiKeyBing"
                imagery-set="Aerial"
              ></vl-source-bingmaps>
              <vl-source-bingmaps
                v-if="(item.id === 'bingMapsAerialWithLabels') & item.visible"
                :api-key="$store.state.settings.apiKeyBing"
                imagery-set="Aerial"
              ></vl-source-bingmaps>
              <vl-source-xyz
                v-if="(item.id === 'mapbox') & item.visible"
                url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGFnb3QiLCJhIjoiY2lveTNjbnRnMDBjOXZtbTJqNGNsNmk1NCJ9.bvTUlzXKG4A2ZejsF1v2Hw"
              ></vl-source-xyz>
              <vl-source-xyz
                v-if="(item.id === 'arcgis') & item.visible"
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                :tile-load-function="arcgisLoad"
              ></vl-source-xyz>
            </vl-layer-tile>

            <vl-layer-vector :z-index="100">
              <vl-source-vector :loader="getDistrictsData" />
              <vl-style-func :factory="styleFunction" />
            </vl-layer-vector>
            <vl-interaction-select
              :features.sync="selectedFeatures"
              @select="onSelect"
              @unselect="onUnselect"
              ident="featuresSelected"
            />
            <vl-interaction-modify
              source="featuresSelected"
              @modifyend="onModifyEnd"
            />
          </vl-map>
        </v-col>
        <DistrictsPassport
          v-show="passport"
          :properties="properties"
          :setDistrict="setDistrict"
        />
      </v-row>
      <v-card>
        <v-snackbar v-model="snackbar" :timeout="timeout" top right outlined>
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              Закрыть
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import WKT from "ol/format/WKT";
import GeoJSON from "ol/format/GeoJSON";
import { mapGetters, mapActions } from "vuex";
import { Style, Stroke, Fill, Text } from "ol/style";
import DistrictsPassport from "../passports/DistrictsPassport.vue";
import TileState from "ol/TileState";

export default {
  name: "DistrictsTab",

  data() {
    return {
      mapChange: 0,
      zoom: 13,
      center: [76.889709, 43.238949],
      selectedFeatures: [],
      formatWKT: new WKT(),
      formatGeoJSON: new GeoJSON(),
      passport: false,
      properties: {},
      snackbar: false,
      text: "",
      timeout: 2000,
      geometryModify: null
    };
  },

  computed: {
    ...mapGetters(["allDistrictsData"]),
    disctricts() {
      if (Object.keys(this.allDistrictsData).length === 0) return {};
      return this.allDistrictsData;
    }
  },

  methods: {
    ...mapActions(["getDistrictsData", "pushDistrictData"]),

    arcgisLoad(imageTile, src) {
      let map = this.$refs.map;
      let zoom = map.getView().getZoom();
      if (zoom > 19) {
        imageTile.setState(TileState.ERROR);
      } else {
        imageTile.getImage().src = src;
      }
    },

    styleFunction() {
      return feature => {
        let baseStyle = new Style({
          fill: new Fill({
            color: String(feature.get("color"))
          }),
          stroke: new Stroke({
            color: "rgba(0,0,255, 1)",
            width: 2
          }),
          text: new Text({
            text: String(feature.get("name")),
            scale: 1.5
          })
        });
        return [baseStyle];
      };
    },
    onSelect(feature) {
      this.passport = true;
      this.properties = Object.assign({}, feature.json.properties);
    },
    onUnselect() {
      this.passport = false;
    },
    async setDistrict({ name, color }) {
      let feature = this.formatGeoJSON.readFeature(this.selectedFeatures[0]);
      let wktRepresenation = this.formatWKT.writeFeature(feature);
      let properties = this.selectedFeatures[0].properties;
      if (this.geometryModify !== null) wktRepresenation = this.geometryModify;
      await this.pushDistrictData({
        id: properties.id,
        geometry: wktRepresenation,
        name: name,
        color: color
      });
      this.geometryModify = null;
      setTimeout(() => {
        this.passport = false;
        this.text = this.$store.state.message.msg;
        this.snackbar = true;
        this.mapChange++;
      }, 1000);
    },
    onModifyEnd(evt) {
      let feature = this.formatGeoJSON.readFeature(evt.json[0].geometry);
      this.geometryModify = this.formatWKT.writeFeature(feature);
    }
  },

  components: { DistrictsPassport }
};
</script>

<style scoped></style>
